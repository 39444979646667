import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"


function Page404 (props) {
    const md = props.data.allFile.edges[0].node.childMarkdownRemark
    return (
      <Layout>
          <div className="about" dangerouslySetInnerHTML={{ __html: md.html }}/>
      </Layout>
    )
}

export default Page404;

export const content = graphql`
    {
        allFile(filter: {name: {eq : "404"}, internal: {mediaType: {eq: "text/markdown"}}, absolutePath: {regex : "\/content/"}}){
            edges{
                node{
                    name
                    childMarkdownRemark{
                        html
                        id
                        frontmatter{
                            name
                        }
                    }
                }
            }
        }
    }
`